<script setup lang="ts">
import ImageComponent from '../pages/images/components/ImageComponent.vue'
import type { TrashItem } from '#build/interfaces/trashItem'

const props = defineProps<{
  items: TrashItem[]
}>()

const localePath = useLocalePath()
</script>

<template>
  <div class="mt-4 masonry">
    <NuxtLink v-for="(item, index) in props.items" :key="index"
      :to="localePath({ name: 'images', params: { id: item.id } })" class="item" :style="{ gridRowEnd: `span ${index % 2 + 1}`}">
      <ImageComponent :image-bucket-url="item.pbjson.picture" :label="item.pbjson.label" />
    </NuxtLink>
  </div>
</template>

<style scoped>
.masonry {
  display: grid;
  grid-template-columns: repeat(2, minmax(150px, 1fr));
  grid-auto-rows: 260px;
}

.item {
  padding: 4px;
  display: flex;
  height: 100%;
}
</style>
