<script setup lang="ts">
import { VSkeletonLoader } from 'vuetify/components/VSkeletonLoader'
import LoggedAsGuest from '../../../components/LoggedAsGuest.vue'
import EmptyGallery from '../../../components/EmptyGallery.vue'
import MasonryWallComponent from '../../../components/MasonryWallComponent.vue'

const showSkeleton = ref(true)
const userData = await getCurrentUser()
const loggedAsGuest = userData.value?.isAnonymous

const { pictures, picsTaken, isPending } = useUserPics(userData.uid)
const { paginatedItems } = useInfiniteScroll(pictures)

onMounted(() => {
  showSkeleton.value = false
})
</script>

<template>
  <v-row v-if="showSkeleton || isPending" class="row">
    <div>
      <v-col v-for="_ in 8" :key="_" cols="6" md="6" lg="6" style="display: inline-block;">
        <VSkeletonLoader :loading="true" class="mx-auto" elevation="2" width="100%" height="100%" type="image" />
      </v-col>
    </div>
  </v-row>
  <div v-else-if="loggedAsGuest" class="center-container">
    <LoggedAsGuest />
  </div>
  <div v-else-if="picsTaken === 0" class="d-flex justify-center align-center" style="height: 70vh;">
    <EmptyGallery :use-title-for-empty-gallery="true" />
  </div>
  <MasonryWallComponent v-else :items="paginatedItems" />
</template>

<style scoped>
h1 {
  color: var(--text-primary-color);
  font-size: 18px;
  margin-top: 2rem;
}

.row {
  margin: 4px 10px 4px 10px;
  margin-left: 0;
  padding-left: 0;
  display: block;
}
</style>
