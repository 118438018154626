import payload_plugin_BlGmaLUnCJ from "D:/PROGRAMAS/trash-main/trash-main/node_modules/.pnpm/nuxt-vuefire@1.0.3_@firebase+app-types@0.9.2_firebase-admin@11.11.1_encoding@0.1.13__firebase_glogswxvxk7xpnecpowuues3te/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_r8hwq3n4eC from "D:/PROGRAMAS/trash-main/trash-main/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.10.0_jiti@1.21_vxilcrm7aercbzb43og36vj3vm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Ot8VaAofGt from "D:/PROGRAMAS/trash-main/trash-main/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.10.0_jiti@1.21_vxilcrm7aercbzb43og36vj3vm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_hoG5t7yRFD from "D:/PROGRAMAS/trash-main/trash-main/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.10.0_jiti@1.21_vxilcrm7aercbzb43og36vj3vm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_i5H92LPui9 from "D:/PROGRAMAS/trash-main/trash-main/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.10.0_jiti@1.21_vxilcrm7aercbzb43og36vj3vm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_FSjDqGnaO1 from "D:/PROGRAMAS/trash-main/trash-main/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.10.0_jiti@1.21_vxilcrm7aercbzb43og36vj3vm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_qltHaWYuR1 from "D:/PROGRAMAS/trash-main/trash-main/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.10.0_jiti@1.21_vxilcrm7aercbzb43og36vj3vm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_3SzZBABLnE from "D:/PROGRAMAS/trash-main/trash-main/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.10.0_jiti@1.21_vxilcrm7aercbzb43og36vj3vm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "D:/PROGRAMAS/trash-main/trash-main/guis/web/.nuxt/components.plugin.mjs";
import prefetch_client_NQQpRCDeLE from "D:/PROGRAMAS/trash-main/trash-main/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.10.0_jiti@1.21_vxilcrm7aercbzb43og36vj3vm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_BfAlEXaM4Q from "D:/PROGRAMAS/trash-main/trash-main/node_modules/.pnpm/nuxt-logrocket@2.0.14_patch_hash=sio7xcg44pvd2wjvm7c5udmzky_magicast@0.3.5_rollup@4.21.2/node_modules/nuxt-logrocket/dist/runtime/plugin.client.mjs";
import composition_QWjqcED67Y from "D:/PROGRAMAS/trash-main/trash-main/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21.2_vue-router@4.4.3_vue@3.5.3_kb5lubmutzcd2mafpbcnz34sr4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_9mIHmtg8eL from "D:/PROGRAMAS/trash-main/trash-main/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21.2_vue-router@4.4.3_vue@3.5.3_kb5lubmutzcd2mafpbcnz34sr4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_LwNRCnHeam from "D:/PROGRAMAS/trash-main/trash-main/node_modules/.pnpm/nuxt-vuefire@1.0.3_@firebase+app-types@0.9.2_firebase-admin@11.11.1_encoding@0.1.13__firebase_glogswxvxk7xpnecpowuues3te/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "D:/PROGRAMAS/trash-main/trash-main/guis/web/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "D:/PROGRAMAS/trash-main/trash-main/guis/web/.nuxt/vuefire-plugin.mjs";
import plugin_d85rET2K9b from "D:/PROGRAMAS/trash-main/trash-main/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node_u2w7cjk374wvvvtadvdxdadx7i/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import errorhandler_client_XoPlZZqvQu from "D:/PROGRAMAS/trash-main/trash-main/guis/web/plugins/errorhandler.client.ts";
import vue_query_wrmMkNpEpe from "D:/PROGRAMAS/trash-main/trash-main/guis/web/plugins/vue-query.ts";
import vuetify_7h9QAQEssH from "D:/PROGRAMAS/trash-main/trash-main/guis/web/plugins/vuetify.ts";
export default [
  payload_plugin_BlGmaLUnCJ,
  revive_payload_client_r8hwq3n4eC,
  unhead_Ot8VaAofGt,
  router_hoG5t7yRFD,
  payload_client_i5H92LPui9,
  navigation_repaint_client_FSjDqGnaO1,
  check_outdated_build_client_qltHaWYuR1,
  chunk_reload_client_3SzZBABLnE,
  components_plugin_KR1HBZs4kY,
  prefetch_client_NQQpRCDeLE,
  plugin_client_BfAlEXaM4Q,
  composition_QWjqcED67Y,
  i18n_9mIHmtg8eL,
  plugin_client_LwNRCnHeam,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  plugin_d85rET2K9b,
  errorhandler_client_XoPlZZqvQu,
  vue_query_wrmMkNpEpe,
  vuetify_7h9QAQEssH
]