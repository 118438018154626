import type { Row } from "@electric-sql/client";
import { useShape } from "./useShape";
import type { TrashItem } from "#build/interfaces/trashItem";

function getUserPictures(userId: string, data: Row[] | undefined): TrashItem[] {
  if (!data) return []

  const allPictures = data
    .sort((a: Row, b: Row) => {
      return new Date(b.ts as string).getTime() - new Date(a.ts as string).getTime()
    }) as unknown as TrashItem[]

    return allPictures.filter((item: TrashItem) => item.pbjson?.userId === userId)
}

export default function useUserPics(userId: string) {
  const config = useRuntimeConfig()
  const urlPath = config.public.ELECTRIC_SQL_URL + 'v1/shape'

  const options = {
    url: urlPath,
    table: 'trashitempb',
  }

  const shapeData = useShape(options);

  const isPending = computed(() => shapeData.value?.isLoading || false)
  const isError = computed(() => shapeData.value?.isError || false)
  const pictures = computed(() => getUserPictures(userId, shapeData.value?.data) )
  const picsTaken = computed(() => pictures.value.length || 0)

  return {
    picsTaken,
    pictures,
    isPending,
    isError,
  }
}
