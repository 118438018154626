import type { TrashItem } from "#build/interfaces/trashItem"

export default function useInfiniteScroll(trashItems: Ref<TrashItem[]>, pageSize=15) {
  let isLoading = false
  const currentPage = ref(1)
  const scrollPosition = ref(0)

  const paginatedItems = computed(() => {
    return trashItems.value.slice(0, currentPage.value * pageSize)
  })

  function saveScrollState(position: number, page: number) {
    scrollPosition.value = position
    currentPage.value = page
  }

  function loadMoreItems() {
    if (currentPage.value * pageSize < trashItems.value.length) {
      currentPage.value++
      saveScrollState(window.scrollY, currentPage.value)
    }
  }

  function handleScroll() {
    if (isLoading) return

    const bottomOfWindow = window.innerHeight + window.scrollY >= document.body.offsetHeight - 500
    if (bottomOfWindow && currentPage.value * pageSize < trashItems.value.length) {
      isLoading = true
      loadMoreItems()
      setTimeout(() => (isLoading = false), 200)
    }
  }

  onMounted(() => {
    window.scrollTo(0, scrollPosition.value)
    window.addEventListener('scroll', handleScroll)
  })

  onBeforeMount(() => {
    window.removeEventListener('scroll', handleScroll)
  })

  return {
    currentPage,
    scrollPosition,
    paginatedItems,
  }
}