<script setup lang="ts">
import { VSkeletonLoader } from 'vuetify/components/VSkeletonLoader'
import MasonryWallComponent from '../../../components/MasonryWallComponent.vue'
import EmptyGallery from '../../../components/EmptyGallery.vue'

const { t } = useI18n()
const showSkeleton = ref(true)

const { pictures, picsTaken, isPending } = useGlobalPics()
const { paginatedItems } = useInfiniteScroll(pictures)

onMounted(() => {
  showSkeleton.value = false
})
</script>

<template>
  <p class="disclaimer font-weight-medium pt-4">
    {{ t('disclaimer') }}
  </p>
  <v-row v-if="showSkeleton || isPending" class="row">
    <v-col v-for="_ in 8" :key="_" cols="6" md="6" lg="6" style="display: inline-block;">
      <VSkeletonLoader :loading="true" class="mx-auto" elevation="2" width="100%" height="100%" type="image" />
    </v-col>
  </v-row>
  <div v-else-if="picsTaken === 0" class="d-flex justify-center align-center" style="height: 70vh;">
    <EmptyGallery :use-title-for-empty-gallery="true" />
  </div>
  <MasonryWallComponent v-else :items="paginatedItems" />
</template>

<style scoped>
.disclaimer {
  text-align: center;
  color: #003C71BF;
}

.row {
  margin: 4px 10px 4px 10px;
  margin-left: 0;
  padding-left: 0;
  display: block;
}

.gallery-container {
  max-width: 800px;
}
</style>
